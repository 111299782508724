import styles from './styles/Footer.module.css';

const Footer = () => {
    return (
        <div id={styles["container"]}>
            <div className={styles.item}>
                <p>I can be reached via email at <span style={{color: "#ffd100"}}>contact@drunkontech.com</span></p>
            </div>
        </div>
    )
}

export default Footer;