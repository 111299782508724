import React from 'react';
import Header from './components/header.js';
import Spacing from './components/spacing.js';
import Blog from './components/blog.js';
import CoolStuff from './components/coolstuff.js';
import Footer from './components/footer.js';

function App() {
  return (
    <div>
      <Header />
      <Spacing />
      <Blog />
      <CoolStuff />
      <Footer />
    </div>
  );
}

export default App;