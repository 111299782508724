import coollogo from './images/coollogo.png';
import deity from './images/deity.jpg';
import crowmuseum from './images/crowmuseum.png';
import waya from './images/waya.png';
import styles from './styles/CoolStuff.module.css';

const CoolStuff = () => {
    return (
        <div id={styles["container"]}>
            <div id={styles["header"]}>
                <img src={coollogo} alt="coollogo" style={{width: "10%", height: "10%"}} />
                COOL STUFF
            </div>
            <div className={styles.item}>
                <h1>Crow Museum of Asian Art: The Crow Collection</h1>
                <p>Japanese culture and history is one of my favorite things to study and experience. It's a very poised culture with an aesthetic second to none in my eyes. From the unique ingredients and flavors in their food to the history behind those flavors and their way of life, <i>The Crow Museum</i> is a great way to get a deeper look into that history. It's filled with vibrant artwork, statues made of opal and jade, and of course, the coveted traditional Samurai armory. I cannot recommend this place enough for a fun date night or out of your own interest.</p>
            </div>
            <div id={styles["images"]} className={styles.item} >
                <img className={styles.image} src={crowmuseum} alt="museum" />
                <img className={styles.image} src={deity} alt="deity" />
            </div>
            <div className={styles.item}>
                <p className={styles.text}>If you feel inspired to top off the evening with Japanese cuisine, check out <i>WAYA Japanese Izakaya</i> just a short distance from the museum.</p>
                <p id={styles["address"]}>
                    <a href="https://www.wayadallas.com/"><img src={waya} alt="waya" style={{maxWidth: 250}} /></a><br />
                    <a href="https://g.co/kgs/WxWTRAo" style={{color: "#222"}}>6334 Gaston Ave, Dallas, TX 75214</a>
                </p>
            </div>
        </div>
    )
}

export default CoolStuff;