import logo from './images/logo.png';
import styles from './styles/Header.module.css';

const Header = () => {
    return (
        <div id={styles["header"]}>
            <img src={logo} id={styles["logo"]} alt="logo" />
        </div>
    )
}

export default Header;