import styles from './styles/Blog.module.css';

const Blog = () => {
    return (
        <div id={styles["container"]}>
            <div id={styles["post"]} style={{width: 1000}}>
                <div id={styles["header"]}>
                    <h1>Back to the Future</h1>
                    <div id={styles["timestamp"]}>
                        <strong>Posted 07/17/2024 at 4:12 PM</strong>
                    </div>
                </div>
                <p>
                    &nbsp; &nbsp; It has been a long summer so far and the learning has been more difficult the futher into web development I dive. I notice the growing disconnect between learner and teacher course after course, they seem to make assumptions about the learner that just prevents us from developing at a steady pace. But progress is there, it's definitely taking a level of creativity on my end to not be stopped by poorly written resources.<br />
                    &nbsp; &nbsp; My current project has been put on hold, I haven't figured out a way to generate PDFs from my code. I do have some others I'd like to start that don't require that, though. Particularly learning how to develop mobile apps and add mobile views to websites. I've spoken to a few friends about ideas they've had for a service or mobile app that seem like they're worth pursuing.
                </p>
                <div id={styles["quote"]}><strong><i>"When you are doing it because you love it, there is no doubt because the only one you answer to is you." - Edmund McMillan</i></strong></div>
                <p>
                    &nbsp; &nbsp; I did receive some encouragement from an unlikely source while helping people troubleshoot their code in a Facebook group. After going in depth to help him understand how a certain function behaved so it's broken down and easy to understand, he said I should be a teacher. It was definitely a nice thing to hear and those small gestures really motivate me to keep at it after 7 months into my year long grind.<br />
                    &nbsp; &nbsp; I've really been busting my ass to understand and implement ReactJS into my daily practices. I see why companies and developers have moved into it so heavily, it really does take JavaScript to a level that will be difficult for any developing language to overtake. In fact, this entire blog has been converted into React as I'm writing it.<br />
                    &nbsp; &nbsp;Pretty cool.
                </p>
            </div>
        </div>
    )
}

export default Blog;